import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { CashflowGraph, BurnRateGraph, Calender, RunwayGauge } from '../../../components';
import Filter from '../../../assets/images/Filter.svg';
import LeftArrow from '../../../assets/images/leftArrow.svg';
import RightArrow from '../../../assets/images/rightArrow.svg';

const ACT = observer(({ }) => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Division, setDivision] = useState(false);
    const graphSectionRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [DateSelector, setDateSelector] = useState(false);
    const [DivisionSelected, setDivisionSelected] = useState(['All']);
    const [PeriodSelected, setPeriodSelected] = useState('Monthly');
    const [isToggled, setIsToggled] = useState(false);
    const [Onset, setOnset] = useState(0);
    const [Divisions, setDivisions] = useState([]);
    const [FilteredComps, setFilteredComps] = useState([]);
    const [LegendsFilter, setLegendsFilter] = useState(['Burnrate', 'Cashflow']);
    const [DateSelectorCard, setDateSelectorCard] = useState(false);
    const [NewDate, setNewDate] = useState(new Date());
    const [Runway, setRunway] = useState(0);
    const [ActOverview, setActOverview] = useState([]);
    const [OverviewWidth, setOverviewWidth] = useState(0)
    useEffect(() => {
        getData();
        const updateDimensions = () => {
            if (graphSectionRef.current) {
                setDimensions({
                    width: graphSectionRef.current.offsetWidth,
                    height: graphSectionRef.current.offsetHeight,
                });
            }
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const getData = async () => {
        store.setLoading(true);
        try {
            let aionsData = await store.getAions();
            if (!aionsData || aionsData === 'error') {
                window.alert('Failed to load data');
                store.setLoading(false);
                return;
            }
            if (aionsData.length === 0) {
                fetchAionsData();
                return
            }
            let data = aionsData[0];
            let newAV = getTotals(data.AV);
            let newACT = getTotals(data.ACT);

            let aex = data.AEX;
            aex.forEach((item) => {
                item.Date = new Date(item.Date);
            })
            aex.sort((a, b) => a.Date - b.Date);
            let comps = [
                {
                    Name: 'ACT',
                    Data: newACT,
                    Overview: newACT
                },
                {
                    Name: 'AV',
                    Data: newAV,
                    Overview: newAV
                },
                {
                    Name: 'AEX',
                    Data: aex,
                    Overview: aex
                }
            ];
            calculateOverallData(data);
            getOverViewChartData(data);
            setDivisions(comps);
            calculateMonthlyData(comps);
            store.setLoading(false);
            forceUpdate();
        } catch (e) {
            console.log(e)
            alert('An error occurred');
            store.setLoading(false);
            return
        }
    };
    const getOverViewChartData = (data) => {
        let act = getTotals(data.ACT);
        let av = getTotals(data.AV);
        let aex = data.AEX;

        aex.forEach((item) => {
            item.Date = changeDate(item.Date);
        });
        act.forEach((item) => {
            item.Date = changeDate(item.Date);
        });
        av.forEach((item) => {
            item.Date = changeDate(item.Date);
        });
        let allItems = [...act, ...av, ...aex];

        const groupedItems = allItems.reduce((acc, item) => {
            const key = `${item.Date}-${item.Type}`;

            if (!acc[key]) {
                acc[key] = {
                    Date: item.Date,
                    Type: item.Type,
                    Income: 0,
                    Expenses: 0,
                };
            }

            acc[key].Income += item.Income;
            acc[key].Expenses += item.Expenses;

            return acc;
        }, {});

        let finalArr = Object.values(groupedItems);
        finalArr.sort((a, b) => a.Date - b.Date);
        finalArr.forEach((item) => {
            item.date = new Date(item.Date)
        });
        finalArr.sort((a, b) => a.date - b.date);
        setActOverview(finalArr)
        forceUpdate();
    };
    const changeDate = (date) => {
        const parsedDate = new Date(date);

        if (isNaN(parsedDate.getTime())) {
            return "Invalid Date";
        }
        const options = { year: 'numeric', month: 'long' };
        const formattedDate = parsedDate.toLocaleDateString('en-US', options);

        return formattedDate;
    };
    const formatData = (data) => {
        let headers = data[0];
        let formattedData = [];

        for (let i = 1; i < headers.length; i++) {
            let obj = { Date: headers[i] };
            for (let j = 1; j < data.length; j++) {
                let clientName = data[j][0];
                clientName = clientName.replace(/[\s-]/g, '');
                let rawValue = data[j][i];
                let value = rawValue ? parseFloat(rawValue.replace(/[R,]/g, '')) : 0;
                obj[clientName] = value;
            }
            formattedData.push(obj);
        }
        formattedData.forEach((item) => {
            item.Date = item.Date;
        });
        return formattedData;
    }
    const calculateRunway = (bankBalance, Monthlyexpense) => {
        const runway = bankBalance / Monthlyexpense;
        return runway;
    };
    const calculateOverallData = (data) => {
        let drawDown = processDrawdownData(data.Telkom);
        let aexData = data.AEX;
        let actData = data.ACT;;
        let avData = data.AV

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() - 1;
        const currentYear = currentDate.getFullYear();


        let actCurrData = actData.filter((obj) => {
            const objDate = new Date(obj.Date);
            return objDate.getMonth() === currentMonth && objDate.getFullYear() === currentYear
        });
        let aexCurrData = aexData.filter((obj) => {
            const objDate = new Date(obj.Date);
            return objDate.getMonth() === currentMonth && objDate.getFullYear() === currentYear
        });
        let avCurrData = avData.filter((obj) => {
            const objDate = new Date(obj.Date);
            return objDate.getMonth() === currentMonth && objDate.getFullYear() === currentYear
        });
        let valuesArr = [];
        actCurrData.forEach((item) => {
            if (item.Type === 'Actual') {
                valuesArr.push(item);
            }
        });
        aexCurrData.forEach((item) => {
            if (item.Type === 'Actual') {
                valuesArr.push(item);
            }
        });
        avCurrData.forEach((item) => {
            if (item.Type === 'Actual') {
                valuesArr.push(item);
            }
        });

        let totalExpenses = 0;

        for (let i = 0; i < valuesArr.length; i++) {
            let item = valuesArr[i];
            let exp = item.Expenses;
            if (Array.isArray(exp)) {
                exp.forEach(item => {
                    totalExpenses += item.Amount;
                });
            } else {
                totalExpenses += 0;
            }
        }
        let telkomValues = drawDown.filter((obj) => {
            const objDate = new Date(obj.Date);
            return objDate.getMonth() === currentMonth - 1 && objDate.getFullYear() === currentYear
        });
        let totalCash = telkomValues[0].TelkomTotal
        let currData = drawDown.filter((obj) => {
            let objDate = new Date(obj.Date);
            return (
                objDate < new Date(currentYear, currentMonth, 0)
            );
        });
        let tempCashBalance = getCashBalance(currData);
        let cashBalance = totalCash - tempCashBalance;
        let runway = calculateRunway(cashBalance, totalExpenses);
        setRunway(runway)
        forceUpdate()
    };
    const getCashBalance = (data) => {
        let telkom = 21000000
        let telkomActDisp = 0
        for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            telkomActDisp += obj.CredippleDisbursement
            telkomActDisp += obj.DKSDisbursement
            telkomActDisp += obj.SSSDisbursement
            telkomActDisp += obj.TACoDisbursement
            telkomActDisp += obj.WeloDisbursement
            telkomActDisp += obj.CocoDisbursement
        }
        let difference = telkom - telkomActDisp
        return difference
    };
    const fetchAionsData = async () => {
        store.setLoading(true)
        try {
            let data = await store.getAionsAV();
            let actData = data.ACT;
            let avData = data.AV;
            let drawdownData = data.Drawdown;
            let newACT = getACTProcessedData(actData);
            let newAV = getProcessedData(avData);

            let aex = []
            data.AEX.forEach((item) => {
                let obj = {
                    Date: new Date(item.Date),
                    Income: item.Amount,
                    Expenses: 0,
                    Cashflow: 0,
                    Burnrate: 0,
                    Type: 'Actual',
                }
                aex.push(obj)
            });
            let obj = {
                AEX: aex,
                ACT: newACT,
                AV: newAV,
                Telkom: drawdownData,
                Date: new Date()
            }
            let createAions = await store.createAions(obj);
            getData();
        } catch (e) {
            alert('Error fetching Aions data.');
            store.setLoading();
            return
        }
    };
    const getProcessedData = (data) => {
        let finalArr = []
        let values = data;
        let dates = values.shift();
        let types = values.shift();

        values.pop();
        values.push([]);
        dates.pop();
        dates.shift();
        types.shift();

        let result = {};
        let other = [];
        for (let i = 0; i < values.length; i++) {
            let currItem = values[i];

            if (currItem.length === 1) {
                let key = currItem[0];
                result[key] = [];
                i++;
                while (i < values.length && values[i].length > 0) {
                    result[key].push(values[i]);
                    i++;
                }
            } else {
                other.push(currItem);
            }
        }
        result['other'] = other;
        Object.keys(result).forEach(key => {
            let arr = result[key];
            if (Array.isArray(arr) && arr.length > 0) {
                let lastArray = arr[arr.length - 1];
                if (lastArray.length > 0 && lastArray[0].includes('Total')) {
                    result[key].pop();
                }
            }
        });
        let income = result.Income;
        let expenses = result.Expenses;
        income.forEach((item) => {
            item.pop();
        });
        expenses.forEach((item) => {
            item.pop();
        });
        for (let p = 0; p < dates.length; p++) {
            let date = dates[p];
            let obj = {
                Type: types[p],
                Date: convertDateFormat(date),
                Income: [],
                Expenses: []
            }
            for (let b = 0; b < income.length; b++) {
                let item = income[b];
                let firstItem = item[0];
                let totalAmount = item[p + 1];
                let numericAmount = parseFloat(
                    totalAmount.replace(/[R,\s]/g, '')
                ) || 0;
                let newObj = {
                    Category: firstItem,
                    Amount: numericAmount
                };
                obj.Income.push(newObj);
            }
            for (let e = 0; e < expenses.length; e++) {
                let item = expenses[e];
                let firstItem = item[0];
                let totalAmount = item[p + 1];
                let numericAmount = parseFloat(
                    totalAmount.replace(/[R,\s]/g, '')
                ) || 0;
                let newObj = {
                    Category: firstItem,
                    Amount: numericAmount
                };
                obj.Expenses.push(newObj);
            }
            finalArr.push(obj);
        }
        return finalArr
    };
    const getACTProcessedData = (data) => {
        let finalArr = []
        let values = data;
        let dates = values.shift();
        let types = values.shift();

        values.pop();
        values.push([]);
        dates.pop();
        dates.shift();
        types.shift();

        let result = {};
        let other = [];
        for (let i = 0; i < values.length; i++) {
            let currItem = values[i];

            if (currItem.length === 1) {
                let key = currItem[0];
                result[key] = [];
                i++;
                while (i < values.length && values[i].length > 0) {
                    result[key].push(values[i]);
                    i++;
                }
            } else {
                other.push(currItem);
            }
        }
        result['other'] = other;
        Object.keys(result).forEach(key => {
            let arr = result[key];
            if (Array.isArray(arr) && arr.length > 0) {
                let lastArray = arr[arr.length - 1];
                if (lastArray.length > 0 && lastArray[0].includes('Total')) {
                    result[key].pop();
                }
            }
        });
        let income = result.Income;
        income.pop();
        let expenses = result.Expenses;
        income.forEach((item) => {
            item.pop();
        });
        expenses.forEach((item) => {
            item.pop();
        });
        for (let p = 0; p < dates.length; p++) {
            let date = dates[p];
            let obj = {
                Type: types[p],
                Date: convertDateFormat(date),
                Income: [],
                Expenses: []
            }
            for (let b = 0; b < income.length; b++) {
                let item = income[b];
                let firstItem = item[0];
                let totalAmount = item[p + 1];
                let numericAmount = parseFloat(
                    totalAmount.replace(/[R,\s]/g, '')
                ) || 0;
                let newObj = {
                    Category: firstItem,
                    Amount: numericAmount
                };
                obj.Income.push(newObj);
            }
            for (let e = 0; e < expenses.length; e++) {
                let item = expenses[e];
                let firstItem = item[0];
                let totalAmount = item[p + 1];
                let numericAmount = parseFloat(
                    totalAmount.replace(/[R,\s]/g, '')
                ) || 0;
                let newObj = {
                    Category: firstItem,
                    Amount: numericAmount
                };
                obj.Expenses.push(newObj);
            }
            finalArr.push(obj);
        }
        return finalArr
    };
    const processDrawdownData = (data) => {
        let headers = data[0];
        let formattedData = [];

        for (let i = 1; i < headers.length; i++) {
            let obj = { Date: headers[i] };
            for (let j = 1; j < data.length; j++) {
                let clientName = data[j][0];
                clientName = clientName.replace(/[\s-]/g, '');
                let rawValue = data[j][i];
                let value = rawValue ? parseFloat(rawValue.replace(/[R,]/g, '')) : 0;
                obj[clientName] = value;
            }
            formattedData.push(obj);
        }
        formattedData.forEach((item) => {
            item.Date = formatToISO(item.Date);
        });
        return formattedData;
    };
    function formatToISO(dateString) {
        const date = new Date(dateString);

        date.setUTCHours(2, 0, 0, 0);
        return date.toISOString();
    }
    const calculateMonthlyData = (data, onset = Onset, date = NewDate) => {
        let finalArr = [];
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let values = item.Data;

            let DataObj = {};
            let currentDate = new Date(date);
            let currentYear = currentDate.getFullYear();
            let currentMonth = currentDate.getMonth() + onset;
            let previousMonth = currentMonth - 1;

            let currentMonthData = values.filter((item) => {
                const objDate = new Date(item.Date).getMonth();
                const objYear = new Date(item.Date).getFullYear();
                return objDate === currentMonth && objYear === currentYear;
            });

            let prevMonthData = values.filter((item) => {
                const objDate = new Date(item.Date).getMonth();
                const objYear = new Date(item.Date).getFullYear();
                return objDate === previousMonth && objYear === currentYear;
            });

            let CurrentMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            for (let i = 0; i < currentMonthData.length; i++) {
                let data = currentMonthData[i];

                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    CurrentMonth.ForecastIncome = income || 0;
                    CurrentMonth.ForecastBurnrate = burnrate || 0;
                    CurrentMonth.ForecastCashflow = cashflow || 0;
                    CurrentMonth.ForecastExpenses = expenses || 0;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    CurrentMonth.Income = income || 0;
                    CurrentMonth.Burnrate = burnrate || 0;
                    CurrentMonth.Cashflow = cashflow || 0;
                    CurrentMonth.Expenses = expenses || 0;
                }
            }
            for (let i = 0; i < prevMonthData.length; i++) {
                let data = prevMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    PreviousMonth.ForecastIncome = income || 0;
                    PreviousMonth.ForecastBurnrate = burnrate || 0;
                    PreviousMonth.ForecastCashflow = cashflow || 0;
                    PreviousMonth.ForecastExpenses = expenses || 0;

                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    PreviousMonth.Income = income || 0;
                    PreviousMonth.Burnrate = burnrate || 0;
                    PreviousMonth.Cashflow = cashflow || 0;
                    PreviousMonth.Expenses = expenses || 0;
                }
            }
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            DataObj.Date = new Date(currentYear, currentMonth);
            DataObj.PreviousDate = new Date(currentYear, currentMonth - 1);
            DataObj.Name = item.Name
            item.BarchartData = DataObj

            finalArr.push(item);
        };
        setFilteredComps(finalArr);
        forceUpdate();
    };
    const calculateYearlyData = (data, onset = Onset, date = NewDate) => {
        let finalArr = [];
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let values = item.Data;

            let DataObj = {};
            let currentDate = new Date(date);
            let currentYear = currentDate.getFullYear() + onset;
            let currentMonthDate = currentDate.getMonth();
            let currentMonthData = values.filter((item) => {
                const objYear = new Date(item.Date).getFullYear();
                return objYear === currentYear;
            });
            let prevMonthData = values.filter((item) => {
                const objYear = new Date(item.Date).getFullYear();
                return objYear === currentYear - 1;
            });

            let CurrentMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            for (let i = 0; i < currentMonthData.length; i++) {
                let data = currentMonthData[i];

                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    CurrentMonth.ForecastIncome += income || 0;
                    CurrentMonth.ForecastBurnrate += burnrate || 0;
                    CurrentMonth.ForecastCashflow += cashflow || 0;
                    CurrentMonth.ForecastExpenses += expenses || 0;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    CurrentMonth.Income += income || 0;
                    CurrentMonth.Burnrate += burnrate || 0;
                    CurrentMonth.Cashflow += cashflow || 0;
                    CurrentMonth.Expenses += expenses || 0;
                }
            }
            for (let i = 0; i < prevMonthData.length; i++) {
                let data = prevMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    PreviousMonth.ForecastIncome += income || 0;
                    PreviousMonth.ForecastBurnrate += burnrate || 0;
                    PreviousMonth.ForecastCashflow += cashflow || 0;
                    PreviousMonth.ForecastExpenses += expenses || 0;

                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    PreviousMonth.Income += income || 0;
                    PreviousMonth.Burnrate += burnrate || 0;
                    PreviousMonth.Cashflow += cashflow || 0;
                    PreviousMonth.Expenses += expenses || 0;
                }
            }
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            DataObj.Date = new Date(currentYear, currentMonthDate);
            DataObj.PreviousDate = new Date(currentYear, currentMonthDate - 1)
            DataObj.Name = item.Name
            item.BarchartData = DataObj

            finalArr.push(item);
        };
        setFilteredComps(finalArr);
        forceUpdate();
    };
    const calculateEntireData = (data) => {
        let finalArr = [];
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let values = item.Data;
            let DataObj = {};
            let CurrentMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            for (let i = 0; i < values.length; i++) {
                let data = values[i];

                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    PreviousMonth.ForecastIncome += income || 0;
                    PreviousMonth.ForecastBurnrate += burnrate || 0;
                    PreviousMonth.ForecastCashflow += cashflow || 0;
                    PreviousMonth.ForecastExpenses += expenses || 0;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    PreviousMonth.Income += income || 0;
                    PreviousMonth.Burnrate += burnrate || 0;
                    PreviousMonth.Cashflow += cashflow || 0;
                    PreviousMonth.Expenses += expenses || 0;
                }
            }
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            DataObj.Name = item.Name
            item.BarchartData = DataObj

            finalArr.push(item);
        };

        setFilteredComps(finalArr);
        forceUpdate();
    };
    const getTotals = (data) => {
        let finalArr = [];
        for (let i = 0; i < data.length; i++) {
            let item = data[i];

            const totalExpenses = Array.isArray(item.Expenses)
                ? item.Expenses.reduce((sum, expense) => sum + expense.Amount, 0)
                : 0;
            const totalIncome = Array.isArray(item.Income)
                ? item.Income.reduce((sum, income) => sum + income.Amount, 0)
                : 0;

            let finalData = {
                Date: item.Date,
                Type: item.Type,
                Income: totalIncome,
                Expenses: totalExpenses
            };
            finalArr.push(finalData);
        };
        return finalArr;
    };
    function convertDateFormat(inputDate) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        const [month, day] = inputDate.split("-");
        const monthIndex = months.indexOf(month);
        const year = parseInt(day, 10) + 2000;
        const newDate = new Date();
        newDate.setFullYear(year, monthIndex, 1);
        newDate.setHours(0, 0, 0, 0);
        newDate.setDate(2);
        return newDate.toISOString();
    };
    const handleOnHover = (e) => {
        let id = e.currentTarget.id;
        if (id === 'Division') {
            setDivision(true);
        };
        if (id === 'Date') {
            setDateSelector(true);
        };
        forceUpdate();
    };
    const closeOnhover = () => {
        setDivision(false);
        setDateSelector(false);
        forceUpdate();
    };
    const handleSelectDivision = (e) => {
        let divisions = [...DivisionSelected];
        let id = e.currentTarget.id;

        if (divisions.includes(id)) {
            divisions = divisions.filter(division => division !== id);
        } else {
            if (id === "All") {
                divisions = ["All"];
            } else {
                divisions = divisions.filter(division => division !== "All");
                divisions.push(id);
            }
        }

        if (divisions.length === 0) {
            divisions = ["All"];
        }

        setDivisionSelected(divisions);
        forceUpdate();
    };
    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    const formatDate = (date) => {
        if (date === undefined) {
            date = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const year = date.getFullYear();
        const month = date.getMonth();

        if (Filter === 'Monthly') {
            return `${monthNames[month]} ${year}`;
        } else if (Filter === 'Yearly') {
            return year.toString();
        } else {
            return '';
        }
    };
    const handleDateFilter = (e) => {
        let filter = e.currentTarget.id;
        let acts = Divisions;
        let onset = 0;
        setOnset(onset);
        if (filter === 'Entire') {
            setPeriodSelected(filter);
            calculateEntireData(acts);
        } else if (filter === 'Monthly') {
            setPeriodSelected(filter);
            calculateMonthlyData(acts, onset);
        } else if (filter === 'Yearly') {
            setPeriodSelected(filter);
            calculateYearlyData(acts, onset);
        }
        forceUpdate();
    };
    const handleMoveForward = () => {
        let onset = Onset + 1;
        setOnset(Onset + 1);
        let acts = Divisions;
        if (PeriodSelected === 'Entire') {
            calculateEntireData(acts)
        } else if (PeriodSelected === 'Monthly') {
            calculateMonthlyData(acts, onset)
        } else if (PeriodSelected === 'Yearly') {
            calculateYearlyData(acts, onset)
        }
        forceUpdate();
    };
    const handleMoveBackward = () => {
        let onset = Onset - 1
        setOnset(Onset - 1);
        forceUpdate();
        let acts = Divisions;
        if (PeriodSelected === 'Entire') {
            calculateEntireData(acts, onset)
        } else if (PeriodSelected === 'Monthly') {
            calculateMonthlyData(acts, onset);
        } else if (PeriodSelected === 'Yearly') {
            calculateYearlyData(acts);
        }
    };
    const legendClicked = (e) => {
        let legends = [...LegendsFilter];
        let id = e.currentTarget.id;

        if (legends.includes(id)) {
            legends = legends.filter((legendId) => legendId !== id);
        } else {
            legends.push(id);
        }
        setLegendsFilter(legends);
        forceUpdate();
    };
    const convertDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const formattedMonth = monthNames[parseInt(month) - 1];
        if (PeriodSelected === 'Monthly') {
            return `${formattedMonth} ${year}`;
        } else if (PeriodSelected === 'Yearly') {
            return year.toString();
        } else {
            return '';
        }
    };
    const openViewCalender = () => {
        setDateSelectorCard(true);
        forceUpdate();
    };
    const closeViewCalender = () => {
        setDateSelectorCard(false);
        forceUpdate();
    };
    const getDate = (dateString) => {
        const [monthName, year] = dateString.split(' ');
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const monthIndex = monthNames.indexOf(monthName);

        if (monthIndex === -1) {
            console.error("Invalid month name provided.");
            return;
        }
        const date = new Date(year, monthIndex, 1);
        date.setHours(0, 0, 0, 0);
        setNewDate(date);
        let acts = Divisions;
        let onset = 0;
        if (PeriodSelected === 'Monthly') {
            calculateMonthlyData(acts, onset, date)
        } else if (PeriodSelected === 'Yearly') {
            calculateYearlyData(acts, onset, date)
        }
        forceUpdate();
    };
    return (
        <div className='OverViewPage2'>
            <div className='overViewTopSect'>
                <div className='divisionSelector' id='Division' onMouseEnter={handleOnHover} onMouseLeave={closeOnhover}>
                    <p>{DivisionSelected.join(', ').length > 25 ? DivisionSelected.join(', ').slice(0, 23) + '...' : DivisionSelected.join(', ')}</p>
                    {(Division) ?
                        <div className='divisionOptions'>
                            <div className='divisionOption' style={{ backgroundColor: DivisionSelected.includes('All') ? '#D5922D' : '' }} id='All' onClick={handleSelectDivision}>All</div>
                            <div className='divisionOption' style={{ backgroundColor: DivisionSelected.includes('AEX') ? '#D5922D' : '' }} id='AEX' onClick={handleSelectDivision}>AEX</div>
                            <div className='divisionOption' style={{ backgroundColor: DivisionSelected.includes('AV') ? '#D5922D' : '' }} id='AV' onClick={handleSelectDivision}>AV</div>
                            <div className='divisionOption' style={{ backgroundColor: DivisionSelected.includes('ACT') ? '#D5922D' : '' }} id='ACT' onClick={handleSelectDivision}>ACT</div>
                            <div className='divisionOption' style={{ backgroundColor: DivisionSelected.includes('AVA') ? '#D5922D' : '' }} id='AVA' onClick={handleSelectDivision}>AVATA</div>
                        </div> : null}
                </div>
                <div className='newDateFilterSelector' onMouseEnter={openViewCalender} onMouseLeave={closeViewCalender}>{NewDate.toLocaleDateString()}
                    {(DateSelectorCard) ?
                        <div className='calebderSect'>
                            <Calender onClick={getDate} />
                        </div> : null}
                </div>
                <button onClick={fetchAionsData} className='syncAVDataBtn'>Sync Data</button>
                <div className='dateFilterSelector' id='Date' onMouseEnter={handleOnHover} onMouseLeave={closeOnhover}>
                    <p>{PeriodSelected}</p>
                    {(DateSelector) ?
                        <div className='dateSelectorOptions'>
                            <div className='divisionOption' style={{ backgroundColor: PeriodSelected === 'Monthly' ? '#D5922D' : '' }} id='Monthly' onClick={handleDateFilter}>Monthly</div>
                            <div className='divisionOption' style={{ backgroundColor: PeriodSelected === 'Yearly' ? '#D5922D' : '' }} id='Yearly' onClick={handleDateFilter}>Yearly</div>
                            <div className='divisionOption' style={{ backgroundColor: PeriodSelected === 'Entire' ? '#D5922D' : '' }} id='Entire' onClick={handleDateFilter}>Entire</div>
                        </div> : null}
                </div>
            </div>
            <div className='availDivisionsChartSect'>
                {FilteredComps.map((item, index) => {
                    if (!DivisionSelected.includes("All") && !DivisionSelected.includes(item.Name)) {
                        return;
                    }
                    let arr = [];
                    item.Overview.forEach((item) => {
                        let obj = {
                            date: new Date(item.Date),
                            Income: item.Income,
                            Expenses: item.Expenses,
                            Cashflow: 0,
                            Burnrate: 0,
                            Type: item.Type,
                        }
                        arr.push(obj);
                    });
                    let BarchartData = item.BarchartData;
                    let date = BarchartData.Date ? BarchartData.Date.toLocaleDateString() : "No Date Available";

                    let screenWidth = window.innerWidth;
                    let screenHeight = window.innerHeight;
                    let firstWidthPercentage = screenWidth * 0.985;

                    let revenueWidth = firstWidthPercentage * 0.45;
                    let overviewWidth = firstWidthPercentage * 0.88;
                    let itemsHeight = screenHeight * 0.87;
                    return (
                        <div className='availDivisionCard' key={index}>
                            <p className='divisionName'>{item.Name}</p>
                            <div className='incomeExpenseChartContainer'>
                                <div className='expensesIncChartSect'>
                                    <div className='chartTopSect'>
                                        {(Filter !== 'Entire') ?
                                            <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackward} />
                                            : null}
                                        <p>Revenue {convertDate(date)} - <span style={{ color: '#D4912D' }}>
                                            R{BarchartData.CurrentMonth.Income !== undefined ? BarchartData.CurrentMonth.Income.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                                        </span>
                                        </p>
                                        {(Filter !== 'Entire') ?
                                            <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForward} />
                                            : null}
                                    </div>
                                    <div className='chart'>
                                        <CashflowGraph Actual={BarchartData} currentAct={BarchartData.CurrentMonth.Income} currentFore={BarchartData.CurrentMonth.ForecastIncome} previousAct={BarchartData.PreviousMonth.Income} previousFore={BarchartData.PreviousMonth.ForecastIncome} width={revenueWidth} height={380} date={formatDate(BarchartData.Date)} prevDate={formatDate(BarchartData.PreviousDate)} type='Income' />
                                    </div>
                                    <div className='legendsSection'>
                                        <div className='actual-Label' /><p style={{ marginRight: '5%' }}>Actual</p>
                                        <div className='forecast-Label' /><p style={{ marginRight: '5%' }}>Forecast</p>
                                        <div className='difference-Label' /><p style={{ marginRight: '5%' }}>Difference</p>
                                    </div>
                                </div>
                                <div className='expensesIncChartSect'>
                                    <div className='chartTopSect'>
                                        {(Filter !== 'Entire') ?
                                            <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackward} />
                                            : null}
                                        <p>Expenses {convertDate(date)} - <span style={{ color: '#D4912D' }}>
                                            R{BarchartData.CurrentMonth.Expenses !== undefined ? BarchartData.CurrentMonth.Expenses.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                                        </span></p>
                                        {(Filter !== 'Entire') ?
                                            <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForward} />
                                            : null}
                                    </div>
                                    <div className='chart'>
                                        <CashflowGraph Actual={BarchartData} currentAct={BarchartData.CurrentMonth.Expenses} currentFore={BarchartData.CurrentMonth.ForecastExpenses} previousAct={BarchartData.PreviousMonth.Expenses} previousFore={BarchartData.PreviousMonth.ForecastExpenses} width={revenueWidth} height={380} date={formatDate(item.Date)} prevDate={formatDate(BarchartData.PreviousDate)} type='Expenses' />
                                    </div>
                                    <div className='legendsSection'>
                                        <div className='actual-Label' /><p style={{ marginRight: '5%' }}>Actual</p>
                                        <div className='forecast-Label' /><p style={{ marginRight: '5%' }}>Forecast</p>
                                        <div className='difference-Label' /><p style={{ marginRight: '5%' }}>Difference</p>
                                    </div>
                                </div>
                            </div>
                            <div className='overViewChartContainer'>
                                <div className='overViewChartSect'>
                                    <div className='runwayAndOverviewChartSect'>
                                        <div className='gaugeContainer'>
                                            {/* <RunwayGauge style={{ marginTop: '6%', width: '165px' }} width={200} height={200} value={800} />
                                            <p>Runway: {22 || 0} Months</p> */}
                                        </div>
                                        <div className='foreSect'>
                                            <p>Forecast</p>
                                            <label className="switch">
                                                <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='overviewGraphCard'>
                                        <BurnRateGraph width={overviewWidth} height={380} data={arr} isForecast={isToggled} exclude={LegendsFilter} />
                                    </div>
                                    <div className='overviewChartLegends'>
                                        <div className='actual-Label' style={{ backgroundColor: LegendsFilter.includes('Income') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Income' />
                                        <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Income') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Income') ? 'line-through' : 'none', cursor: 'pointer' }} onClick={legendClicked} id='Income'>Revenue</p>
                                        <div className='forecast-Label' style={{ backgroundColor: LegendsFilter.includes('Expenses') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id="Expenses" />
                                        <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Expenses') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Expenses') ? 'line-through' : 'none', cursor: 'pointer' }} onClick={legendClicked} id="Expenses">Expenses</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <p className='overallPHeader'>Overall</p>
                <div className='aionsOverviewChart'>
                    <div className='aionsRunwayChart'>
                        <div className='runwaySectChart'>
                            <RunwayGauge style={{ marginTop: '6%', width: '125px' }} width={200} height={200} value={Runway} />
                            <p>Runway: {Math.round(Runway) || 0} Months</p>
                        </div>
                        <div className='forecastSect'>
                            <p>Forecast</p>
                            <label className="switch">
                                <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                                <span className="slider round"></span>
                            </label>
                        </div>

                    </div>
                    <div className='aionsOverviewCanvas' ref={graphSectionRef}>
                        <BurnRateGraph width={dimensions.width} height={dimensions.height} data={ActOverview} isForecast={isToggled} exclude={LegendsFilter} />
                    </div>
                    <div className='aionsOverviewLegends'>
                        <div className='actual-Label' style={{ backgroundColor: LegendsFilter.includes('Income') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Income' />
                        <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Income') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Income') ? 'line-through' : 'none', cursor: 'pointer' }} onClick={legendClicked} id='Income'>Revenue</p>
                        <div className='forecast-Label' style={{ backgroundColor: LegendsFilter.includes('Expenses') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id="Expenses" />
                        <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Expenses') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Expenses') ? 'line-through' : 'none', cursor: 'pointer' }} onClick={legendClicked} id="Expenses">Expenses</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ACT;